import Layout from '@/components/layout/Layout';
import {
  IAuthenticatedWebsiteNavigation,
  IFooterNavigation,
  IPublicWebsiteNavigation,
} from 'core/strapi/types';
import { GetStaticPropsResult, NextPage } from 'next';
import Head from 'next/head';
import {
  fetchAuthenticatedNavigation,
  fetchFooterNavigation,
  fetchPublicNavigation,
} from '../core/strapi/StrapiApi';

type Custom404Props = {
  navigationPublic: IPublicWebsiteNavigation;
  navigationAuthenticated: IAuthenticatedWebsiteNavigation;
  navigationFooter: IFooterNavigation;
};

const Custom404: NextPage<Custom404Props> = ({
  navigationPublic,
  navigationAuthenticated,
  navigationFooter,
}) => {
  return (
    <Layout
      navigationPublic={navigationPublic}
      navigationAuthenticated={navigationAuthenticated}
      navigationFooter={navigationFooter}
    >
      <Head>
        <title>Un oh, page not found!</title>
      </Head>
      <main>
        <section className="hero-cta">
          <div className="container">
            <div className="copy col-8">
              <h1 className="primary">Oh no, page not found!</h1>
            </div>
          </div>
          <div className="bottom-graphic"></div>
        </section>
      </main>
    </Layout>
  );
};

export const getStaticProps = async (): Promise<GetStaticPropsResult<Custom404Props>> => {
  const [navigationAuthenticated, navigationPublic, navigationFooter]: [
    IAuthenticatedWebsiteNavigation,
    IPublicWebsiteNavigation,
    IFooterNavigation
  ] = await Promise.all([
    fetchAuthenticatedNavigation(),
    fetchPublicNavigation(),
    fetchFooterNavigation(),
  ]);
  return {
    props: {
      navigationAuthenticated,
      navigationPublic,
      navigationFooter,
    },
    revalidate: 1,
  };
};

export default Custom404;
